.name_home {
  font-family: "Montserrat", Lato, "Noto Sans", sans-serif;
  color: #bec6d1;
  text-align: center;
  text-shadow: 3px 3px 0 black;
  font-size: 4em;
  letter-spacing: 0.05em;
  display: flex;
  justify-content: center;
  padding-top: 9%;
}

.title_home {
  font-family: "Montserrat", Lato, "Noto Sans", sans-serif;
  color: #bec6d1;
  font-size: xx-large;
  text-align: center;
  display: flex;
  justify-content: center;
  padding-top: 4%;
  margin-bottom: 80px;
}

.main_home {
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;

}

.page_home {
  width: 80%;
  margin: auto;
  height: 93.2%;
  position: static;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.night {
  z-index: -1;
  transform: rotate(22deg);
  position: relative;
  top: 10px;
  right: 80px;
}

@media screen and (max-width: 750px) {
  .page_day_out {
    width: 75%;
  }

  .night {
    display: none;
  }
}