.name_about {
  font-family: "Montserrat", Lato, "Noto Sans", sans-serif;
  color: #bec6d1;
  text-align: center;
  text-shadow: 3px 3px 0 black;
  font-size: 4em;
  letter-spacing: 0.05em;
  display: flex;
  justify-content: center;
  padding-top: 11.9%;
  margin-bottom: 50px;
  }

.main_about {
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
}

.page_about {
  width: 75%;
  margin: 0 auto;
  height: 93.5%;
  position: static;

}

.profile {
  display: flex;
  color: #bec6d1;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 40px;
}

.profile-image {
  display: grid;
  grid-template-columns: 1fr;
  max-width: 400px;
  max-height: 400px;
  margin-bottom: 40px;
}

.profile-para {
  max-width: 768px;
  color: #bec6d1;
  text-align: start;
  font-size: 22px;
  word-spacing: 2px;
  flex: 2;
  min-width: 250px;
  text-align: center;
}

@media screen and (max-width: 1270px) {
  .profile {
    flex-direction: column;
  }
  
  .page_about {
    width: 90%;
  }
}

@media screen and (max-width: 490px) {

  .profile-image {
    max-width: 300px;
    max-height: 300px;
  }
}