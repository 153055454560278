.colum {
    color: #bec6d1;
    text-align: center;
    font-size: large; 
    width: 240px;
}

.table {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-evenly;
}