nav {
    position: static;
    top: 0;
    left: 0;
    width: 100%;
    margin: auto;
    height: 59px;
}

.nav-scroll {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    margin: auto;
    height: 59px;
}

.list {
    list-style-type: none;
    background: #0b0d13;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
    gap: 1%;
}

.list-scroll {
    list-style-type: none;
    background: #0b0d13;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
    gap: 1%;
}

.items {
    font-size: 20px;
    text-transform: uppercase;
    color: #f1f1f1;
    cursor: pointer;
}


.block:hover {
    background-color: #d5d4db;
    color: rgb(0, 0, 0);
    transition: 0.4s;
}

.btn {
    display: none;
    position: absolute;
    right: 10px;
    top: 7px;
    padding: 5px;
    color: #000;
    font-size: 20px;
}

.block {
    display: block;
    width: 100%;
    border: none;
    background-color: #0b0d13;
    color: white;
    padding: 14px 28px;
    font-size: 26px;
    cursor: pointer;
    text-align: center;
    font-family: "Deja Vu Sans", Lato, "noto sans", sans-serif;
    font-weight: bold;
    opacity: 1;
  }


@media screen and (max-width: 750px){

    nav {
        position: static;
        width: auto;
        z-index: -1;
    }
    
    .list {
        flex-direction: column;
        height: auto;
    }
    .items:nth-child(1){
        border-top: 1px solid rgba(255, 255, 255, 1);
        margin-top: 50px;
    }
    .items {
        width: 100%;
        border-top: 1px solid rgba(255, 255, 255, 1);
        text-align: center;
        margin-right: 0px;
        z-index: 1000;
    }
    .btn {
        display: block;
        width: 105vw;
        border: none;
        background-color: black;
        margin-top: -10px;
        color: white;
        padding: 16px;
        padding-right: 16px;
        font-size: 26px;
        cursor: pointer;
        text-align: center;
        font-family: "Deja Vu Sans", Lato, "noto sans", sans-serif;
        font-weight: bold;
        position: relative;
    }

    .btn:hover {
        background-color: black;
        color: grey;
        transition: 0.4s;
    }

}
