.main_friendsgiving {
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
}
  
.name_friendsgiving {
  font-family: "Montserrat", Lato, "Noto Sans", sans-serif;
  color: #bec6d1;
  text-align: center;
  text-shadow: 3px 3px 0 black;
  font-size: 4em;
  letter-spacing: 0.05em;
  justify-content: center;
  margin-top: 15%;
}

.page_friendsgiving {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #bec6d1;
  
  }


.date_friendsgiving {
  font-family: "Montserrat", Lato, "Noto Sans", sans-serif;
  color: #bec6d1;
  text-align: center;
  text-shadow: 3px 3px 0 black;
  font-size: 2em;
  letter-spacing: 0.05em;
  display: flex;
  justify-content: center;
  margin-top: 2%;
}

.desc_friendsgiving {
  font-family: "Montserrat", Lato, "Noto Sans", sans-serif;
  color: #bec6d1;
  text-align: center;
  text-shadow: 3px 3px 0 black;
  font-size: 2em;
  letter-spacing: 0.05em;
  justify-content: center;
  margin-top: 7%;
  margin-bottom: 1.5em;
}

.para_friendsgiving {
  color: #bec6d1;
  text-align: justify;
  font-size: 1.4em;
  margin-top: 1em;
  margin-bottom: 1em;
  width: 100%;
}

.grid_friendsgiving {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

@media screen and (max-width: 1080px) {
  .page_friendsgiving {
    width: 75%;
  }
}


@media screen and (max-width: 750px) {
  .page_friendsgiving {
    width: 85%;
  }
}