.quiz {
    background-color: #252d4a;
    width: 600px;
    min-height: 300px;
    height: min-content;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 20px;
    display: flex;
    justify-content: space-evenly;
    color: #bec6d1;
  }
  
  .score-section {
    display: flex;
    font-size: 24px;
    align-items: center;
  }
  
  /* QUESTION/TIMER/LEFT SECTION */
  .question-section {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  .question-count {
    margin-bottom: 20px;
    font-size: 28px;
  }
  
  .question-count span {
    font-size: 28px;
  }
  
  .question-text {
    margin-bottom: 12px;
  }
  
  .timer-text {
    background: rgb(230, 153, 12);
    padding: 15px;
    margin-top: 20px;
    margin-right: 20px;
    border: 5px solid rgb(255, 189, 67);
    border-radius: 15px;
    text-align: center;
  }
  
  /* ANSWERS/RIGHT SECTION */
  .answer-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .quiz-button {
    width: 100%;
    font-size: 16px;
    color: #ffffff;
    background-color: #252d4a;
    border-radius: 15px;
    display: flex;
    padding: 5px;
    justify-content: flex-start;
    align-items: center;
    border: 5px solid #234668;
    cursor: pointer;
  }
  
  .correct {
    width: 100%;
    font-size: 16px;
    color: #ffffff;
    border-radius: 15px;
    display: flex;
    padding: 5px;
    justify-content: flex-start;
    align-items: center;
    border: 5px solid #234668;
    cursor: pointer;
    background-color: #2f922f;
  }
  
  .incorrect {
    width: 100%;
    font-size: 16px;
    color: #ffffff;
    border-radius: 15px;
    display: flex;
    padding: 5px;
    justify-content: flex-start;
    align-items: center;
    border: 5px solid #234668;
    cursor: pointer;
    background-color: #ff3333;
  }
  
  button:hover {
    background-color: #555e7d;
  }
  
  button:focus {
    outline: none;
  }
  
  button svg {
    margin-right: 5px;
  }

.lore {
  background-color: #252d4a;
  width: 600px;
  min-height: 300px;
  height: min-content;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #bec6d1;
}

.lore-title {
  font-size: 30px;
  width: auto;
}

.next-question-button-container {
  align-self: flex-start;
  width: 80px;
  position: relative;
  top: 137px;
}

.next-question-button {
  width: 80px;
  height: 40px;
}

.lore-text {
  font-size: 20px;
}