.main_trip_london {
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
}

.page_trip_london {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #bec6d1;
}

.title_stuff_trip_london {
  display: flex;
  width: 100%;
  justify-content: center;
  color: #bec6d1;
  margin-bottom: 40px;
}

.title_stuff_trip_london h1 {
  display: flex;
  font-size: 4em;
  text-shadow: 3px 3px 0 black;
  font-family: "Montserrat", Lato, "Noto Sans", sans-serif;
  margin-right: auto;
  margin-left: auto;
  padding-right: 40px;
  text-align: center;
}

.page_trip_london h2 {
  font-size: 2em;
  text-shadow: 3px 3px 0 black;
  font-family: "Montserrat", Lato, "Noto Sans", sans-serif;
  text-align: center;
  margin-bottom: 40px;
}

.back_arrow_trip {
  height: 50px;
  width: 50px;
  color: #bec6d1;
}

.back_arrow-box_trip {
  display: flex;
  align-items: center;
}

.desc_trip_london {
  margin-left: auto;
  margin-right: auto;
  font-size: 2rem;
  font-family: "Montserrat", Lato, "Noto Sans", sans-serif;
  text-align: center;
  text-shadow: 3px 3px 0 black;
  font-size: 2em;
  letter-spacing: 0.05em;
  margin-bottom: 40px;
}

.para_trip_london {
  color: #bec6d1;
  text-align: justify;
  font-size: 1.4em;
  margin-top: 1em;
  margin-bottom: 1em;
  width: 100%;
}

.map_1 {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
  height: auto;
}

.spiderman-image {
  width: 100%;
  margin-bottom: 40px;
  height: auto;
}

.images_in_grid {
  width: 100%;
}

.grid_for_images_london {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 15px;
  column-gap: 15px;
  margin-bottom: 40px;
}

.grid_for_images_2_london {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 15px;
  column-gap: 15px;
  margin-bottom: 20px;
}

@media screen and (max-width: 1080px) {
  .page_trip_london {
    width: 80%;
  }
}

@media screen and (max-width: 750px) {
  .page_trip_london {
    width: 90%;
  }
  .grid_for_images_london {
    grid-template-columns: 1fr;
  }
  .grid_for_images_2_london {
    grid-template-columns: 1fr;
  }
}
