.container-about-weather {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background: rgba(0, 0, 0, 0.781);
    width: 600px;
    padding: 25px;
    border: 1px solid;
    border-radius: 25px;
    height: 500px;
}

.container-about-weather-no-show {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: rgba(0, 0, 0, 0.781);
    width: 600px;
    padding: 25px;
    border: 1px solid;
    border-radius: 25px;
    height: 500px;
}

.input-about-weather {
    height: 50px;
    width: 400px;
    padding: 20px;
    border-radius: 30px;
    font-size: large;
}

.input-about-weather-no-show {
    height: 50px;
    width: 400px;
    padding: 20px;
    border-radius: 30px;
    font-size: large;
    margin-top: 21px;
    margin-bottom: 30px;
}

.weather-content-rows-beginning {
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
    width: 400px;
    justify-self: flex-start;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: x-large;
}

.weather-all {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 500px;
}

.weather-now {
    align-content: space-between;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 240;
}

.weather-content-rows-name {
    color: white;
    font-size: xx-large;
    text-transform: capitalize;
    flex: 1;
}

.weather-content-rows-description {
    color: white;
    width: 240px;
    font-size: xx-large;
    text-transform: capitalize;
    flex: 1;
}

.weather-content-rows-temp {
    display: flex;
    flex-direction: row;
    color: white;
    font-size: xx-large;
    align-items: center;
    flex: 1;
}

.weather-content-humidity {
    color: white;
    font-size: xx-large;
    align-items: center;
    margin-top: 30px;
    flex: 1;
}

.weather-content-wind {
    flex: 1;
    color: white;
    margin-right: auto;
    font-size: xx-large;
    margin-top: 30px;
}

.weather-forcast {
    display: grid;
    grid-template-columns: auto auto;
}

.weather-forcast-days {
    display: flex;
    height: 130px;
    width: 100px;
    color: white;
    font-size: xx-large;
    flex-direction: column;
    justify-content: center;
    border: 2px solid;
    margin: 10px;
    text-shadow: 3px 3px 0 black;
    border-radius: 10px;
    flex: 1;
    align-content: space-around;
}

.weather-forcast-days-image {
    flex: 1;
}

.weather-forcast-days-text {
    flex: 1;
    text-align: center;
}

.weather-forcast-bottom {
    display: flex;
    width: 250px;
    height: 150px;
    align-content: center;
    position: relative;
    top: 165px;
    right: 220px;
}

.weather-forcast-days-text-no {
    flex: 1;
    text-align: center;
    font-size: 20px;
    position: relative;
    bottom: 60px;
}