.name_projects {
    font-family: "Montserrat", Lato, "Noto Sans", sans-serif;
    color: #bec6d1;
    text-align: center;
    text-shadow: 3px 3px 0 black;
    font-size: 4em;
    letter-spacing: 0.05em;
    display: flex;
    justify-content: center;
    padding-top: 8.5%;
}
  
.main_projects {
    height: 100vh;
    width: 100vw;
    overflow-y: auto;
    overflow-x: hidden;
}

.page_projects {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    height: 93.5%;
    position: static;
    padding-top: 51px;
    display: flex;
    flex-direction: column;
    gap: 100px;
}

.double-image {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 20px;
}

.display-left-projects {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.display-left-projects-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 800px;
    margin-left: 30px;
}

.display-left-projects-text p {
    font-size: 24px;
    color: #bec6d1;
    text-align: center;
}

.display-left-projects-text h2 {
    font-size: 40px;
    text-align: center;
    color: #bec6d1;
    margin-bottom: 30px;
}

.display-right-projects {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    align-items: center;
}

.display-right-projects-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 800px;
    margin-right: 30px;
}

.display-right-projects-text p {
    font-size: 24px;
    color: #bec6d1;
    text-align: center;
}

.display-right-projects-text h2 {
    font-size: 40px;
    text-align: center;
    color: #bec6d1;
    margin-bottom: 30px;
}

.display-noodleworld-projects {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.display-noodleworld-projects-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: 30px;
}

.display-noodleworld-projects-text p {
    font-size: 24px;
    color: #bec6d1;
    text-align: center;
}

.display-noodleworld-projects-text h2 {
    font-size: 40px;
    text-align: center;
    color: #bec6d1;
    margin-bottom: 30px;
}

.noodleworld-image {
    max-width: 100%;
    flex-wrap: wrap;
}

.display-TRDB-projects {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    align-items: center;
}

.display-TRDB-projects-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.display-TRDB-projects-text p {
    font-size: 24px;
    color: #bec6d1;
    text-align: center;
}

.display-TRDB-projects-text h2 {
    font-size: 40px;
    text-align: center;
    color: #bec6d1;
    margin-bottom: 30px;
}

.display-TRDB-projects-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    width: 100%;
    font-size: 20px;
}

.display-TRDB-projects-grid p {
    margin-top: 10px;
    font-size: 20px;
}

.TRDB-Image {
    max-width: 100%;
    flex-wrap: wrap;
}

.Image-Desc {
    
}

@media screen and (max-width: 1530px) {
    .display-noodleworld-projects {
        flex-direction: column-reverse;
    }

    .display-noodleworld-projects-text {
        margin-bottom: 40px;
        flex-wrap: wrap;
        margin-left: 0;
    }

    .display-noodleworld-projects-text h2 {
        flex-wrap: wrap;
    }

    .display-TRDB-projects {
        flex-direction: column-reverse;
    }

    .display-TRDB-projects-text {
        margin-bottom: 40px;
        flex-wrap: wrap;
        margin-left: 0;
    }

    .display-TRDB-projects-text h2 {
        flex-wrap: wrap;
    }

    .double-image {
        flex-direction: row;
    }

}

@media screen and (max-width: 1250px) {
    .display-left-projects {
        flex-direction: column-reverse;
    }

    .display-left-projects-text {
        margin-bottom: 40px;
        width: 100%;
        margin-left: 0;
    }

    .display-right-projects {
        flex-direction: column-reverse;
    }

    .display-right-projects-text {
        margin-bottom: 40px;
        width: 100%;
        margin-right: 0;
    }
}

@media screen and (max-width: 780px) {
    .double-image {
        flex-direction: column;
    }

    .display-TRDB-projects-grid {
        grid-template-columns: repeat(1, 1fr);
    }
}