.main_lovejoy {
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
}
  
.name_lovejoy {
  font-family: "Montserrat", Lato, "Noto Sans", sans-serif;
  color: #bec6d1;
  text-align: center;
  text-shadow: 3px 3px 0 black;
  font-size: 4em;
  letter-spacing: 0.05em;
  justify-content: center;
  margin-top: 15%;
}

.page_lovejoy {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #bec6d1;
  
  }


.date_lovejoy {
  font-family: "Montserrat", Lato, "Noto Sans", sans-serif;
  color: #bec6d1;
  text-align: center;
  text-shadow: 3px 3px 0 black;
  font-size: 2em;
  letter-spacing: 0.05em;
  display: flex;
  justify-content: center;
  margin-top: 2%;
}

.desc_lovejoy {
  font-family: "Montserrat", Lato, "Noto Sans", sans-serif;
  color: #bec6d1;
  text-align: center;
  text-shadow: 3px 3px 0 black;
  font-size: 2em;
  letter-spacing: 0.05em;
  justify-content: center;
  margin-top: 7%;
  margin-bottom: 1.5em;
}

.para_lovejoy {
  color: #bec6d1;
  text-align: justify;
  font-size: 1.4em;
  margin-top: 1em;
  margin-bottom: 1em;
  width: 100%;
}

.grid_lovejoy {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(315px, 1fr));
  gap: 20px;
}

.grid_lovejoy_1 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(315px, 1fr));
  gap: 20px;
}

.lovejoy_image {
  width: 100%;
}

.caption_lovejoy {
  font-size: medium;
  text-align: center;
  margin-top: 10px;
}

.video_lovejoy_1 {
  margin-left: auto;
  margin-right: auto;
}

.warning {
  text-align: center;
}

.list_of_songs {
  margin-top: 20px;
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media screen and (max-width: 1080px) {
  .page_lovejoy {
    width: 75%;
  }
}


@media screen and (max-width: 750px) {
  .page_lovejoy {
    width: 85%;
  }
}