.page_resume {
    max-width: 950px;
    margin: 0 auto;
    height: 93.5%;
    position: static;
    padding-top: 51px;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 25px;
    padding-right: 25px;

}

.name_resume {
    font-family: "Montserrat", Lato, "Noto Sans", sans-serif;
    color: #bec6d1;
    text-align: center;
    text-shadow: 3px 3px 0 black;
    font-size: 4em;
    letter-spacing: 0.05em;
    display: flex;
    justify-content: center;
    padding-top: 14.0%;
}
    
.main_resume {
    height: 100vh;
    width: 100vw;
    overflow-y: auto;
    overflow-x: hidden;
    position: static;
}

.div_button_resume {
    margin-top: 70px;
    height: 100px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}


.download_resume {
    height: 45px;
    width: 300px;
    border: black;
    border-radius: 0px;
    color: #bec6d1;
    background-color: rgb(24, 23, 23);
    box-shadow: 3px 3px 0 rgb(17, 17, 17);
    font-size: medium;
    border-radius: 10px;
}

.download_resume:hover {
    background-color: #d5d4db;
    color: rgb(0, 0, 0);
    transition: 0.4s;
    cursor: pointer;
}

.resume-main-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.resume_title {
    color: #bec6d1;
    font-size: 35px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.resume_list_item {
    color: #bec6d1;
    font-size: 20px;
    margin-left: 20px;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
}

.resume_sub_item {
    color: #bec6d1;
    font-size: 20px;
    margin-left: 50px;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
}

.dates {
    float: right;
    align-self: flex-end;
}

.certs {
    display: flex;
    flex-direction: column;
    width: 290px;
}

.certs-grid {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin-bottom: 50px;
}

.certs i {
    color: #bec6d1;
    font-size: 20px;
    text-align: center;
    flex-direction: column;
    margin-bottom: 20px;
}

.badge {
    width: 290px;
    height: 290px;
}

@media screen and (max-width: 650px){
    .dates {
        float: none;
    }

    .resume_sub_item {
        color: #bec6d1;
        font-size: 20px;
        justify-content: space-between;
        margin-top: 10px;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
    }

    .resume_list_item {
        color: #bec6d1;
        font-size: 20px;
        flex-direction: column;
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
    }

}

@media screen and (max-width: 650px){
    .certs-grid {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .certs {
        justify-self: center;
        margin-bottom: 30px;
    }
}